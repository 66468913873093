import state from './moduleFormState'
import actions from './moduleFormActions'
import mutations from './moduleFormMutations'
import getters from './moduleFormGetters'

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
