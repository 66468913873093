export default function ({next, router}) {
  let user = JSON.parse(localStorage.getItem('userInfo'));
  if (user.status == 'to_be_confirmed') {
    return next({'name': 'user-not-confirmed'});
  }
  return next();
}



