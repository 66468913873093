export default acl => {
  acl.rule('isAuthorized', (user) => {
    return user.status === 'activated'
  })
  acl.rule('isAdmin', (user) => user.userRole === 'Admin')
  acl.rule('isBuyer', (user) => user.userRole === 'Buyer')
  acl.rule('isSupplier', (user) => user.userRole === 'Supplier')
  acl.rule('isSupervisor', (user) => user.userRole === 'Supervisor')
  acl.rule('isTechnician', (user) => user.userRole === 'Technician')
  acl.rule('isSQE', (user) => user.userRole === 'SQE')
  acl.rule('isFinance', (user) => user.userRole === 'Finance')

  acl.rule('viewRfqs', (user) => [
    'rfq-read_own',
    'rfq-read',
    'rfq-attachment-read_own',
    'rfq-attachment-read',
    'rfq-note-read',
    'rfq-note-read_own'
  ].some(item => user.permissions.includes(item)))

  acl.rule('updateRfqs', (user) => [
    'rfq-update_own',
    'rfq-item-update',
    'rfq-item-add_offer',
    'rfq-item-update_offer',
    'rfq-note-read',
    'rfq-note-read_own'
  ].some(item => user.permissions.includes(item)))

  acl.rule('viewRfps', (user) => ['rfp-read_own', 'rfp-read'].some(item => user.permissions.includes(item)))
  acl.rule('updateRfps', (user) => ['rfp-update_own', 'rfp-update'].some(item => user.permissions.includes(item)))

  acl.rule('viewProjects', (user) => ['project-read_own', 'project-read'].some(item => user.permissions.includes(item)))
  acl.rule('viewCompanies', (user) => ['company-read_own', 'company-read'].some(item => user.permissions.includes(item)))
  acl.rule('viewProducts', (user) => ['user-read_own', 'user-read'].some(item => user.permissions.includes(item)))
  acl.rule('viewUsers', (user) => ['user-read_own', 'user-read'].some(item => user.permissions.includes(item)))
  acl.rule('viewTeams', (user) => ['team-read_own', 'team-read'].some(item => user.permissions.includes(item)))

  window.__PERMISSIONS.forEach(permission => {
    acl.rule(permission.name, (user) => user.permissions.includes(permission.name))
  })
}
