import axios from '../../../axios/index.js'
import store from '../../../../store/store.js'

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

export default {
  init () {
    axios.interceptors.response.use(function (response) {
      return response
    }, (error) => {
      const { config, response } = error
      const originalRequest = config

      if (response && (response.status === 401 || response.status === 400)) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch('auth/fetchAccessToken')
            .then(function (access_token, test = isAlreadyFetchingAccessToken) {
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(access_token)
            }).catch((err) => {
              isAlreadyFetchingAccessToken = false
              if (localStorage.getItem('accessToken')) {
                localStorage.removeItem('accessToken')
                document.location.href = "/pages/login";
              }
            })
        }
        if (response && response.status === 403) {
          console.log('FROM AXIOS INTERCEPTOR. 403!')
        }

        return new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`
            resolve(axios(originalRequest))
          })
        })
      }
      return Promise.reject(error)
    })
  },
  getAuth(){
    return  axios.post('/api/auth/me',{},{
      headers: {
        'Authorization': 'Bearer'+' '+ localStorage.getItem('accessToken')
      }
    })
  },
  login (email, pwd) {
    return axios.post('/api/auth/login', {
      email,
      password: pwd
    })
  },
  registerUser (name, email, pwd) {
    return axios.post('/api/auth/register', {
      name: name,
      email,
      password: pwd
    })
  },
  resetPassword (payload) {
    return axios.post('/api/auth/resetPassword', payload)
  },
  refreshToken () {
    return axios.post('/api/auth/refresh-token', {accessToken: localStorage.getItem('accessToken')})
  },

  recoverPassword (email ) {
    return axios.post('/api/auth/recoverPassword', {
      email
    })
  },

  changeProfile(payload) {
    return axios.put('/api/user-management/changeProfile', payload)
  },
  changeUserInfo(payload) {
    return axios.put('/api/user-management/changeUserInfo', payload)
  }
}
