// axios
import axios from 'axios'
const baseURL = process.env.MIX_APP_URL;
export const CancelToken = axios.CancelToken;

export const axiosItem = axios
export default axios.create({
  baseURL
  // You can add your headers here
})
