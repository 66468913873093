export default {
  companyScales: [],
  form: {},
  formFields: [],
  formFieldsToStore: [],
  formFormulas: [],
  formSubmissions: [],
  searchResults: [],
  settingsSelectedFormulas: [],
  settingsFormulasOptions: [],
  loading: false
}
