import Echo from "laravel-echo"

window.Pusher = require('pusher-js');
var token = localStorage.getItem('accessToken');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '94209c1413aebedde44f',
    cluster: 'us2',
    encrypted: true,
    auth: {
        headers: {
            Authorization: 'Bearer ' + token
        },
    },
});
