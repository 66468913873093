/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vue moment
Vue.use(require('vue-moment'))

// Vuesax Component Framework
import Vuesax from 'vuesax'

Vue.use(Vuesax)


// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// API Calls
import './http/requests'

// Theme Configurations
import '../themeConfig.js'

// Auth0 Plugin
import AuthPlugin from './plugins/auth'
Vue.use(AuthPlugin)


// Vue Router
import router from './router'

import browserAcl from './acl/browserAcl'
import Acl from 'vue-browser-acl'
import state from './store/state'

Vue.use(Acl, state.AppActiveUser, browserAcl, {router})

// import Permissions from './mixins/Permissions'
// Vue.mixin(Permissions)


// Globally Registered Components
import './globalComponents.js'


// Vuex Store
import store from './store/store'


/* i18n*/import i18n from './i18n/i18n'


// Vuexy Admin Filters
import './filters/filters'


import * as moment from 'moment';
Vue.prototype.Moment = window.moment = moment;

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')


// VeeValidate

import VeeValidate, {Validator} from 'vee-validate';
import VeeValidateMessagesEN from "vee-validate/dist/locale/en";
import VeeValidateMessagesIT from "vee-validate/dist/locale/it";
Validator.localize('it', VeeValidateMessagesIT);
Validator.localize('en', VeeValidateMessagesEN);
Vue.use(VeeValidate)

Vue.prototype.$eventBus = new Vue();


// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyB4DDathvvwuwlwnUu7F4Sow3oU22y5T1Y',
    libraries: 'places' // This is required if you use the Auto complete plug-in
  }
})

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput)


import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
require('../bootstrap')


// Feather font icon

require('@assets/css/iconfont.css')
// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false


window.Vue = require('vue')
window.moment=

Vue.component('chat-component', require('./components/ChatComponent.vue').default)

import VueChatScroll from 'vue-chat-scroll'

Vue.use(VueChatScroll)
const filter = function (text, length, clamp) {

  clamp = clamp || '...'
  const node = document.createElement('div')
  node.innerHTML = text
  const content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
}
Vue.filter('truncate', filter)

Vue.prototype.$AuthProviders = window.authProviders

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
