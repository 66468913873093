export default function verificationEmail({ next, router }) {
  return next();
  /*
  let user = JSON.parse(localStorage.getItem('userInfo'));
  console.log('verificationEmail',user);
  if (!user.email_verified_at) {
    return next({'name' : 'verication-email'});
  } else {
    return next();
  }
*/

}
